import { License } from './license';

export const LICENSE: License[] = [
  {
    policy: '1',
    license: 'Usuario nombrado',
    users: 50,
    startDate: 'Hoy',
    endDay: 'Mañana',
    status: 'Vigente',
    availableUsers: 1,
    active: 1
  }
];
