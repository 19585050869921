import { Injectable } from '@angular/core';

import { LICENSE } from './license.mock';
import { License } from './license';

const ONE = 1;

@Injectable()
export class LicenseService {
  constructor() { }

  /**
   *  @description This method perform substraction to the users available to add
   */
  public discountUserToAdd(): void {
    let license = this.getActiveLicense();
    license.availableUsers = license.availableUsers - 1;
  }

  /**
   * @description The method returns the active license
   * @return {License} active license
   */
  public getActiveLicense(): License {
    return LICENSE.find(_licese => _licese.active === 1);
  }

  /**
   * @description This method returns all the license from the backend
   * @return {License[]}
   */
  public getLicenses(): License[] {
    return LICENSE;
  }

  /**
   * @description This method returns the number of licenses availables to add
   * @return {number} about licenses availables to add
   */
  public getUsersLeftToAdd(): number {
    return LICENSE.find(_licese => _licese.active === ONE).availableUsers;
  }
}
