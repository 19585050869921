export class ConfirmOptions {

  // Confirm responsive option
  static readonly RESPONSIVE: boolean = true;
  // Confirm appendTo Target Element to attach the dialog
  static readonly APPEND_TO: any = 'body';
  // Confirm widht element option
  static readonly WIDTH: number = 300;
  // Confirm icon to show in confirmDialog
  static readonly ICON: string = 'null';
  // Confirm default closable
  static readonly CLOSABLE: boolean = true;
  // Confirm default Height
  static readonly HEIGHT: number = 225;

}
