import * as _ from 'lodash';

import { Label } from '../administration/label-management/label';

const LOCALSTORAGE_MODULE = 'labels';
export class TraslateUtils {

  static labels: Label[] = JSON.parse(localStorage.getItem(LOCALSTORAGE_MODULE));
  /**
   * @description get the traslation of a label
   * @param {string} label label to get the traslation
   * @returns {string} return the traslated label.
   */
  public static getTraslatedName(componentName: string): string {
    if (!this.labels) {
      this.labels = JSON.parse(localStorage.getItem(LOCALSTORAGE_MODULE));
    }
    let selectedLabel: Label;
    let traslatedLabel: string;
    selectedLabel = _.find(TraslateUtils.labels, (label: Label) => {
      return _.isEqual(label.key, componentName);
    });
    traslatedLabel = selectedLabel ? selectedLabel.label : componentName;
    return traslatedLabel;
  }

  /**
   * @description get the traslation names of the list
   * @param {any[]} listNames list which will be traslate
   * @param {string} [field] Field to translate
   * @param {string} [targetField] Field to set the translated result
   * @returns {any[]} list with the names traslated
   */
  public static getTraslatedNames(listNames: any[], field?: string, targetField?: string): any[] {
    field = _.isUndefined(field) ? 'name' : field;
    targetField = _.isUndefined(targetField) ? 'translatedName' : targetField;
    _.forEach(listNames, (row: any, index: number) => {
      listNames[index][targetField] = this.getTraslatedName(row[field]);
    });
    return listNames;
  }

  /**
   * @description get the traslation of a label
   * @param {string} componentName label to get the traslation
   * @returns {string} return the traslated label.
   */
   public static getTraslatedError(componentName: string): string {
    if (!this.labels) {
      this.labels = JSON.parse(localStorage.getItem(LOCALSTORAGE_MODULE));
    }
    let selectedLabel: Label;
    let traslatedLabel: string;
    selectedLabel = _.find(TraslateUtils.labels, (label: Label) => {
      return _.isEqual(label.errorCode, componentName);
    });
    traslatedLabel = selectedLabel ? selectedLabel.label : componentName;
    return traslatedLabel;
  }
}
