import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ENVIRONMENT } from '../../../environments/environment';
import { Equipment } from './equipment';
import { HandleError } from '../../utils/utils';

@Injectable()
export class EquipmentService {

  constructor(private http: HttpClient) {}

  /**
   * @description Gets the list of equipments
   * @return {Observable<Equipment[]} - List of Equipments
   */
  public getEquipmentsInfo(): Observable<Equipment[]> {
    return this.http.get<Equipment[]>(ENVIRONMENT.API + '/equipments/')
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Gets an equipment by its id
   * @param {number} equipmentId Equipment Id
   * @return {Observable<Equipment>} Equipment information
   */
  public getEquipmentById(equipmentId: number): Observable<Equipment> {
    return this.http.get<Equipment>(ENVIRONMENT.API + '/equipments/' + equipmentId)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Saves the new Equipment
   * @param {Equipment} equipment Equipment's object with the information which will be created
   * @return {Observable<number>} - Created Equipment'id
   */
  public saveEquipment(equipment: Equipment): Observable<number> {
    return this.http.post<number>(ENVIRONMENT.API + '/equipments/', equipment)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Updates the Equipment
   * @param {Equipment} equipment Equipment's object with the information which will be updated
   * @return {Observable<number>} - Updated Equipment
   */
  public updateEquipment(equipment: Equipment): Observable<number> {
    return this.http.put<number>(ENVIRONMENT.API + '/equipments/', equipment)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Deletes the current equipment
   * @param {Equipment} equipment Equipment's object which will be deleted
   * @return {Observable<Equipment>} - Deleted Equipment
   */
  public deleteEquipment(equipment: Equipment): Observable<Equipment> {
    return this.http.put<Equipment>(ENVIRONMENT.API + '/equipments/' + equipment.id, null)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Gets an equipment by its id
   * @param {number} equipmentId Equipment Id
   * @return {Observable<Equipment>} Equipment information
   */
  public getEquipmentByIdRf(equipmentId: number): Observable<Equipment> {
    return this.http.get<Equipment>(ENVIRONMENT.API + '/equipments/equipment-rf/' + equipmentId)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Gets an equipment by its id and warehouse relation ship
   * @param {number} equipmentId Equipment Id
   * @param {number} warehouseId Warehouse id
   * @return {Observable<Equipment>} Equipment information
   */
  public verifyEquipmentWarehouse(equipmentId: number, warehouseId: number): Observable<Equipment> {
    return this.http.get<Equipment>(ENVIRONMENT.API + `/equipments/equipment-rf/${equipmentId}?warehouse=${warehouseId}`)
      .pipe(catchError(HandleError.handleErrorObservable));
  }

  /**
   * @description Unlock an equipment
   * @param {Equipment} equipment Equipment object
   * @return {Observable<Equipment>} Equipment information
   */
  public unlockEquipment(equipment: Equipment): Observable<Equipment> {
    return this.http.put<Equipment>(ENVIRONMENT.API + '/auth/unlock-equipment/', equipment)
      .pipe(catchError(HandleError.handleErrorObservable));
  }
}
