import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SidebarWrapperService {
  private sidebarWrapperSubject: Subject<boolean>;

  constructor() {
    this.sidebarWrapperSubject = new Subject<boolean>();
  }

  /**
 * @description Sends signal for toggling sidebar.
 * @returns {void}
 */
  public sidebarWrapperToggle(toggle: boolean): void {
    this.sidebarWrapperSubject.next(toggle);
  }

  /**
   * @description Subscribes to sidebar subject.
   */
  public sidebarWrapperSubscribe(): Observable<boolean> {
    return this.sidebarWrapperSubject.asObservable();
  }
}
