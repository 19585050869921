import { Activity } from '../activity/activity';

export class Role {
  id: number;
  name: string;
  description: string;
  isDefault: boolean;
  isActive: boolean;
  activities: Activity[];

  constructor() {
    this.id = undefined;
    this.name = '';
    this.description = '';
    this.isDefault = false;
    this.isActive = false;
    this.activities = [];
  }
}
