import { catchError } from 'rxjs/operators';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import * as _ from 'lodash';

import { AppInsightsMonitoringService } from '../../monitoring/appInsights/app-insights-monitoring.service';
import { AuthService } from '../services/auth.service';
import { WepError } from '../../wep-error';

const INVALID_TOKEN = 'INVALID_TOKEN';

@Injectable()
export class WepErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService,
    private appInsightsMonitoringService: AppInsightsMonitoringService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((err: WepError) => {
      this.appInsightsMonitoringService.logError(err, { Message: err.message, Url: request.url,
        Error: err.error, Method: request.method, body: request.body } );
      if (_.isEqual(err.error.message, INVALID_TOKEN)) {
        this.authService.logout();
      }
      return throwError(err);
    }));
  }
}
