export class WorkZone {
  id: number;
  name: string;
  description: string;
  minimumTemperature: number;
  concurrentEquipments: number;
  sequence: string;
  isActive: boolean;
  isVoicePicking: boolean;
  lastChange: Date;
  userId: number;

  constructor() {
    this.id = undefined;
    this.name = '';
    this.description = '';
    this.minimumTemperature = undefined;
    this.concurrentEquipments = undefined;
    this.sequence = '';
    this.isActive = false;
    this.isVoicePicking = false;
    this.lastChange = new Date();
    this.userId = undefined;
  }
}
