import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { LoginModule } from '../../../login/login.module';
import { PagesModule } from '../../../pages.module';
import { WepErrorInterceptor } from '../interceptors/error.interceptor';
import { WepInterceptor } from '../interceptors/wep.interceptor';

@NgModule({
  imports: [HttpClientModule, LoginModule, PagesModule],
  exports: [HttpClientModule, LoginModule, PagesModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: WepErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: WepInterceptor, multi: true }
  ]
})
export class AuthModule { }
