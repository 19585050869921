import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api/selectitem';
import * as _ from 'lodash';

import { SessionService } from './session.service';
import { Session } from './session';
import { LabelService } from '../administration/label-management/label.service';

const SUCCESS = 'success';

@Component({
  selector: 'app-sd-session',
  templateUrl: 'session.component.html'
})

export class SessionComponent implements OnInit {
  public sessions: Array<Session>;
  public error: any;
  public selectedSession: Session = null;
  public cols: any[];
  public columnOptions: SelectItem[];
  public lbl = {
    caducate : this.labelService.labelText('caducateSession'),
    caducateAll : this.labelService.labelText('caducateAllSession')
  };

  constructor(public sessionService: SessionService,
    private labelService: LabelService) { }

  /**
   * @description Init function
   * @return {void}
   */
  public ngOnInit(): void {
    this.getSessions();
    this.paintTable();
  }

  public onRowSelect(): void {
  }

  /**
   * @description This method retreives the data from the backend calling the sessionService getSessions method
   * @return {void}
   */
  public getSessions(): void {
    this.sessions = new Array<Session>();
    this.sessionService
      .getSessions().subscribe(
        (responseData) => {
          let resposeSession = JSON.parse(responseData);
          if (resposeSession.data.status === SUCCESS) {
            _.map(resposeSession.data.sessions, (sessionBack) => {
              this.sessions.push(
                {
                  id: sessionBack.id,
                  ip: sessionBack.ip,
                  startDate: sessionBack.startDate,
                  user: sessionBack.user.id,
                  account: sessionBack.id,
                  warehouse: sessionBack.id,
                  equipment: sessionBack.id
                });
            });
          } else {
            this.error = resposeSession.err.description;
          }
        },
        (error) => {
          this.error = error;
        }
      );
  }

  /**
   * @description This method verifies when the user's session has expired
   * @ return {any}
   */
  public caducateSession(): any {
    this.sessionService.caducateSession(this.selectedSession).subscribe(
      (data) => {
        this.getSessions();
      },
      (error) => {
        // TODO
      });
  }

  /**
   * @description This method verifies when the logged-in user's system session has expired to remove it from the system
   * @ return {any}
   */
  public caducateAllSession(): any {
    this.sessionService.caducateAllSession().subscribe(
      (data) => {
        this.getSessions();
      },
      (error) => {
        // TODO
      });
  }

  /**
   * @description This method initialize the columns with its properly value and header
   * @return {void}
   */
  public paintTable(): void {
    this.cols = [
      {field: 'ip', header: 'IP'},
      {field: 'startDate', header: 'Start Date'},
      {field: 'user', header: 'User'},
      {field: 'account', header: 'Account'},
      {field: 'warehouse', header: 'Warehouse'},
      {field: 'equipment', header: 'Equipment'}
    ];

    this.columnOptions = [];
    for (let i = 0; i < this.cols.length; i++) {
      this.columnOptions.push({label: this.cols[i].header, value: this.cols[i]});
    }

  }

}
