import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ENVIRONMENT } from '../../../../environments/environment';
import { HandleError } from '../../../utils/utils';
import { RESETPASSWORD } from './password-recovery.mock';
import { Role } from '../../role/role';
import { RoleService } from '../../role/role.service';
import { User } from '../../user/user';

@Injectable()
export class PasswordRecoveryService {
  constructor(private roleService: RoleService, private http: HttpClient) { }

  /**
   * @description This method adds an user to the  mock in the future it will be the DB
   * @param {User} _user User's object to add
   */
  public addPassRecoveryUser(_user: User): void {
    RESETPASSWORD.push(_user);
  }

  /**
   * @description This method deletes an user to the  mock in the future it will be the DB
   * @param {User} _user User's object to delete
   */
  public deletePassRecoveryUser(_user: User): void {
    let index = RESETPASSWORD.indexOf(_user);
    RESETPASSWORD.splice(index, 1);
  }

  /**
   * @description This method validates if the user has the privileges to perform the action
   * @param {User} _user User's object
   * @return {boolean} true || false according to the user's role
   */
  public hasPermissionToReset(_user: User): boolean {
    let role: Role = new Role();
    role.name = 'Super Usuario';
    role.id = 1;
    role.isDefault = true;

    if (this.roleService.hasRole(_user, role)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * @description The method returns the list of users with recovery password message.
   * @return {Array<User>} list of users with recovery password message.
   */
  public getUsersRecoveryPass(): Array<User> {
    return RESETPASSWORD;
  }

  /**
   * @description This method checks if the user has the reset password flag activated.
   * @param {User} _user User's user object.
   * @return {boolean} true || false according to the user's status.
   */
  hasResetPassword(_user: User): boolean {
    if (RESETPASSWORD.find(user => user.id === _user.id)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * @description This method resets the user's password changing the object directly.
   * @param {User} _user User's object.
   * @param {Observable<string>} newPass new user password.
   */
  public resetPass(_user: User, newPass: string): Observable<string> {
    let passObject: Object = { password: newPass };
    return this.http.put<string>(ENVIRONMENT.API + '/users/' + _user.id + '/changePassword', passObject)
      .pipe(catchError(HandleError.handleErrorObservable));
  }
}
