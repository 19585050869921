import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseFormWrapperComponent } from './base-form-wrapper.component';

@NgModule({
  imports: [CommonModule],
  declarations: [BaseFormWrapperComponent],
  exports: [BaseFormWrapperComponent],
})
export class BaseFormWrapperModule { }
