import { Area } from '../area/area';
import { Interfaceble } from '../../shared/interfaces/interfaceble';
import { WorkZone } from '../work-zone/work-zone';

export class Location implements Interfaceble {
  public id: number;
  public name: string;
  public aisle: string;
  public side: string;
  public back: string;
  public level: string;
  public isAvailable: boolean;
  public isOnError: boolean;
  public isOnCounting: boolean;
  public maxCapacity: number;
  public holdCapacity: number;
  public usedCapacity: number;
  public maxWeight: number;
  public locationAccess: any;
  public translatedLocationAccess: string;
  public locationAccessId: number;
  public checkNumber: string;
  public allowMixedLot: boolean;
  public allowMixedInventoryStatus: boolean;
  public allowMixedProduct: boolean;
  public pickingSequence: string;
  public putawaySequence: string;
  public area: Area;
  public workZone: WorkZone;
  public isActive: boolean;
  public lastChange: Date;
  public userId: number;
  public location: string;
  public isAssigned: boolean;

  constructor() {
    this.id = undefined;
    this.name = '';
    this.aisle = '';
    this.side = '';
    this.back = '';
    this.level = '';
    this.isAvailable = false;
    this.isOnError = false;
    this.isOnCounting = false;
    this.maxCapacity = undefined;
    this.holdCapacity = undefined;
    this.usedCapacity = undefined;
    this.maxWeight = undefined;
    this.locationAccessId = undefined;
    this.checkNumber = '';
    this.allowMixedLot = false;
    this.allowMixedInventoryStatus = false;
    this.allowMixedProduct = false;
    this.pickingSequence = '';
    this.putawaySequence = '';
    this.area = new Area();
    this.workZone = new WorkZone();
    this.isActive = false;
    this.lastChange = new Date();
    this.userId = undefined;
    this.location = '';
    this.isAssigned = false;
    this.translatedLocationAccess = null;
  }

  public toInterface() {
    return {
      name: this.name
    };
  }
}
