import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppInsightsMonitoringService } from './shared/monitoring/appInsights/app-insights-monitoring.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  public appInsights: AppInsightsMonitoringService;

  constructor(private router: Router) {
    this.appInsights = new AppInsightsMonitoringService(router);
  }
}
