import { Component, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import * as _ from 'lodash';

import { ConfirmOptions } from '../../../constants/confirm-options';
import { LabelService } from '../../../../administration/label-management/label.service';

@Component({
  selector: 'app-confirm-wrapper',
  templateUrl: 'confirm-wrapper.component.html',
  styleUrls: ['./confirm-wrapper.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmWrapperComponent implements OnChanges {
  @Input() header?: string;
  @Input() icon?: string;
  @Input() width?: number;
  @Input() height?: number;
  @Input() responsive?: boolean;
  @Input() appendTo?: any;
  @Input() closable?: boolean;
  @Input() open: boolean;
  @Input() message?: string;
  @Output() userConfirm: EventEmitter<boolean> = new EventEmitter<boolean>();

  public lbl = {
    agree: this.labelService.labelText('confirmButton'),
    undo: this.labelService.labelText('cancelButton'),
    message: this.labelService.labelText('confirmDeleteMessage'),
    header: this.labelService.labelText('warningTitle')
  };

  constructor(private confirmService: ConfirmationService,
    private labelService: LabelService) {
      this.confirmInit();
      this.open = false;
  }

  /**
  *@description Evaluate the event when you click delete, to open the confirm.
  *@param {SimpleChanges} changes.
  */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes['open'].currentValue) {
      this.checkResponse();
    }
  }

  /**
  *@description Function that parses user response when showing confirm
  *@return {void}
  */
  public checkResponse(): void {
    this.confirmService.confirm({
      message: this.message,
      accept: () => {
        this.userConfirm.emit(true);
      },
      reject: () => {
        this.userConfirm.emit(false);
      }
    });
  }

  /**
  * @description Initializes confirm's options and configuration
  * @return {void}
  */
  private confirmInit(): void {
    this.appendTo = _.isUndefined(this.appendTo) ? ConfirmOptions.APPEND_TO : this.appendTo;
    this.closable = _.isUndefined(this.closable) ? ConfirmOptions.CLOSABLE : this.closable;
    this.header = _.isUndefined(this.header) ? this.lbl.header : this.header;
    this.height = _.isUndefined(this.height) ? ConfirmOptions.HEIGHT : this.height;
    this.icon = _.isUndefined(this.icon) ? ConfirmOptions.ICON : this.icon;
    this.message = _.isUndefined(this.message) ? this.lbl.message : this.message;
    this.responsive = _.isUndefined(this.responsive) ? ConfirmOptions.RESPONSIVE : this.responsive;
    this.width = _.isUndefined(this.width) ? ConfirmOptions.WIDTH : this.width;
  }
}
