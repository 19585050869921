import { IMenuInterface } from '../../../models/menu-interface';

export const ROUTES: IMenuInterface[] = [
  {
    name: 'Labels',
    title: 'Internacionalización',
    routerLink: '/labels',
    parent: 'Configuration',
    priority: 2
  },
  {
    name: 'Policy and Parameters',
    title: 'Parámetros',
    routerLink: '/policy-param',
    parent: 'Configuration',
    priority: 3
  },
  {
    name: 'Assignment policies',
    title: 'Políticas de asignación',
    routerLink: '/assignment-policy',
    parent: 'Configuration',
    priority: 4
  },
  {
    name: 'Put away policies',
    title: 'Políticas de acomodo',
    routerLink: '/put-away-policy',
    parent: 'Configuration',
    priority: 5
  },
  {
    name: 'Role Management',
    title: 'Roles',
    routerLink: '/role/management',
    parent: 'Configuration',
    priority: 6
  },
  {
    name: 'Users',
    title: 'Usuarios',
    routerLink: '/users',
    parent: 'Configuration',
    priority: 7
  },
  {
    name: 'Print Label',
    title: 'Etiquetas de impresión',
    routerLink: '/printer-label',
    parent: 'Configuration',
    priority: 8
  },
  {
    name: 'RF Monitor',
    title: 'Monitor RF',
    routerLink: '/rf-monitor',
    parent: 'Configuration',
    priority: 9
  },
  {
    name: 'Input Field Configuration',
    title: 'Configuración de campos de entrada',
    routerLink: '/input-field-configuration',
    parent: 'Configuration',
    priority: 10
  },
  {
    name: 'Movement Between Area',
    title: 'Movimiento entre áreas',
    routerLink: '/movement-between-area',
    parent: 'Configuration',
    priority: 11
  },
  {
    name: 'Receipt Policy',
    title: 'Políticas de recibo',
    routerLink: '/receipt-policy',
    parent: 'Configuration',
    priority: 12
  },
  {
    name: 'Dashboards Configuration',
    title: 'Configuración de dashboards',
    routerLink: '/dashboard-configuration',
    parent: 'Configuration',
    priority: 13
  },
  {
    name: 'Barcode Configuration',
    title: 'Configuración de código compuesto',
    routerLink: '/barcode-configuration',
    parent: 'Configuration',
    priority: 14
  },
  {
    name: 'Assignment Zone User',
    title: 'Asignación de usuarios por zona',
    routerLink: '/assignment-zone-user',
    parent: 'Configuration',
    priority: 15
  },
  {
    name: 'Movement Path',
    title: 'Ruta de Paso',
    routerLink: '/movement-path',
    parent: 'Configuration',
    priority: 16
  },
  {
    name: 'Task Type Priority',
    title: 'Prioridad por tipo de tarea',
    routerLink: '/task-type-priority',
    parent: 'Configuration',
    priority: 17
  },
  {
    name: 'Archiving',
    title: 'Archivo',
    routerLink: '/archiving',
    parent: 'Configuration',
    priority: 18
  },
  {
    name: 'Report Configuration',
    title: 'Configuración de reportes',
    routerLink: '/report-configuration',
    parent: 'Configuration',
    priority: 19
  },
  {
    name: 'Appointment Configuration',
    title: 'Configuración de cita',
    routerLink: '/appointment-configuration',
    parent: 'Configuration',
    priority: 20
  },
  {
    name: 'Label Configuration',
    title: 'Configuración de etiquetas',
    routerLink: '/label-configuration',
    parent: 'Configuration',
    priority: 21
  },
  {
    name: 'Interface View',
    title: 'Interfaces con error',
    routerLink: '/interfaces',
    parent: 'Configuration',
    priority: 22
  },
  {
    name: 'Warehouse',
    title: 'Almacenes',
    routerLink: '/warehouse',
    parent: 'Catalogs',
    priority: 1
  },
  {
    name: 'Account',
    title: 'Cuentas',
    routerLink: '/account',
    parent: 'Catalogs',
    priority: 2
  },

  {
    name: 'Area',
    title: 'Áreas',
    routerLink: '/area',
    parent: 'Catalogs',
    priority: 3
  },
  {
    name: 'Work Zone',
    title: 'Zonas de Trabajo',
    routerLink: '/work-zone',
    parent: 'Catalogs',
    priority: 4
  },
  {
    name: 'Work Area',
    title: 'Áreas de Trabajo',
    routerLink: '/work-area',
    parent: 'Catalogs',
    priority: 5
  },
  {
    name: 'Location',
    title: 'Localidades',
    routerLink: '/location',
    parent: 'Catalogs',
    priority: 6
  },
  {
    name: 'Equipment Type',
    title: 'Tipos de Equipo',
    routerLink: '/equipment-type',
    parent: 'Catalogs',
    priority: 7
  },
  {
    name: 'Equipment',
    title: 'Equipos',
    routerLink: '/equipment',
    parent: 'Catalogs',
    priority: 8
  },
  {
    name: 'Family',
    title: 'Familia',
    routerLink: '/family',
    parent: 'Catalogs',
    priority: 9
  },
  {
    name: 'Subfamily',
    title: 'Subfamilia',
    routerLink: '/subfamily',
    parent: 'Catalogs',
    priority: 10
  },
  {
    name: 'Product',
    title: 'Productos',
    routerLink: '/product',
    parent: 'Catalogs',
    priority: 11
  },
  {
    name: 'UOM',
    title: 'Unidades de Medida',
    routerLink: '/uom',
    parent: 'Catalogs',
    priority: 12
  },
  {
    name: 'Footprint',
    title: 'Huellas',
    routerLink: '/footprint',
    parent: 'Catalogs',
    priority: 13
  },
  {
    name: 'Customer',
    title: 'Clientes',
    routerLink: '/customer',
    parent: 'Catalogs',
    priority: 14
  },
  {
    name: 'Accessory',
    title: 'Accesorios',
    routerLink: '/accessory',
    parent: 'Catalogs',
    priority: 15
  },
  {
    name: 'Reason',
    title: 'Motivos',
    routerLink: '/reason',
    parent: 'Catalogs',
    priority: 16
  },
  {
    name: 'Building',
    title: 'Edificios',
    routerLink: '/building',
    parent: 'Catalogs',
    priority: 17
  },
  {
    name: 'Carrier',
    title: 'Transportistas',
    routerLink: '/carrier',
    parent: 'Catalogs',
    priority: 18
  },
  {
    name: 'Inventory Status',
    title: 'Estatus de Inventario',
    routerLink: '/inventory-status',
    parent: 'Catalogs',
    priority: 19
  },
  {
    name: 'Progression',
    title: 'Progresión',
    routerLink: '/progression',
    parent: 'Catalogs',
    priority: 20
  },
  {
    name: 'Carriers',
    title: 'Transportistas',
    routerLink: '/carrier',
    parent: 'Catalogs',
    priority: 21
  },
  {
    name: 'Address',
    title: 'Direcciones',
    routerLink: '/address',
    parent: 'Catalogs',
    priority: 22
  },
  {
    name: 'Vehicle',
    title: 'Vehículos',
    routerLink: '/vehicle',
    parent: 'Catalogs',
    priority: 23
  },
  {
    name: 'Vehicle Type',
    title: 'Tipo de Vehículos',
    routerLink: '/vehicle-type',
    parent: 'Catalogs',
    priority: 24
  },
  {
    name: 'Supplier',
    title: 'Proveedores',
    routerLink: '/supplier',
    parent: 'Catalogs',
    priority: 25
  },
  {
    name: 'Inventory Report',
    title: 'Reporte de Inventario',
    routerLink: '/inventory/report',
    parent: 'Reports',
    priority: 1,
    mobile: true
  },
  {
    name: 'Movements History',
    title: 'Histórico de Movimientos',
    routerLink: '/movement/history',
    parent: 'Reports',
    priority: 2
  },
  {
    name: 'Receipt Report',
    title: 'Generar acuse de recibo',
    routerLink: '/receipt-report',
    parent: 'Reports',
    priority: 3
  },
  {
    name: 'Inventory report location',
    title: 'Reporte de inventario por localidad',
    routerLink: '/inventory-report-location',
    parent: 'Reports',
    priority: 4
  },
  {
    name: 'Reallocate Product',
    title: 'Reubicar Producto',
    routerLink: '/reallocate/product',
    parent: 'Reallocate',
    priority: 1,
    mobile: true
  },
  {
    name: 'Reallocate LPN',
    title: 'Reubicar LPN',
    routerLink: '/reallocate/lpn',
    parent: 'Reallocate',
    priority: 2,
    mobile: true
  },
  {
    name: 'Reallocate Case',
    title: 'Reubicar caja',
    routerLink: '/reallocate/case',
    parent: 'Reallocate',
    priority: 3,
    mobile: true
  },
  {
    name: 'Generate Inventory',
    title: 'Generación de Inventario',
    routerLink: '/generate-count',
    parent: 'Inventory',
    priority: 1
  },
  {
    name: 'Physical Count',
    title: 'Toma de Inventario',
    routerLink: '/inventory/physical-count',
    parent: 'Inventory',
    priority: 2,
    mobile: true
  },
  {
    name: 'Inventory Analysis',
    title: 'Análisis de Inventario',
    routerLink: '/inventory/inventory-analysis',
    parent: 'Inventory',
    priority: 3
  },
  {
    name: 'Quantity Adjustment',
    title: 'Ajuste de Cantidad',
    routerLink: '/inventory/quantity-adjustment',
    parent: 'Inventory',
    priority: 4
  },
  {
    name: 'Programmed Inventory Count',
    title: 'Configuración de Conteos Cíclicos Programados',
    routerLink: '/programmed-cycle-count',
    parent: 'Inventory',
    priority: 5
  },
  {
    name: 'Manual Inventory Count',
    title: 'Configuración de Conteos Cíclicos Manuales',
    routerLink: '/manual-cycle-count',
    parent: 'Inventory',
    priority: 6
  },
  {
    name: 'Change Attribute',
    title: 'Cambio de atributo',
    routerLink: '/inventory-control/attribute-change',
    parent: 'Inventory',
    priority: 8,
    mobile: true
  },
  {
    name: 'Serial Traceability',
    title: 'Rastreabilidad de Seriales',
    routerLink: '/inventory/serial-traceability',
    parent: 'Inventory',
    priority: 9
  },
  {
    name: 'Code Change',
    title: 'Cambio de código',
    routerLink: '/code-change',
    routerLinkMobile: '/code-change-rf',
    parent: 'Inventory',
    priority: 10,
    mobile: true,
  },
  {
    name: 'Picking List Configuration',
    title: 'Configuración de Listas de Surtido',
    routerLink: '/picking-list-configuration',
    parent: 'Planning',
    priority: 1
  },
  {
    name: 'Shipment Order Load',
    title: 'Carga Orden de Salida',
    routerLink: '/shipment-order-load',
    parent: 'Planning',
    priority: 2
  },
  {
    name: 'Planning',
    title: 'Planeación',
    routerLink: '/planning',
    parent: 'Planning',
    priority: 3
  },
  {
    name: 'Assignment Summary',
    title: 'Resumen de asignación',
    routerLink: '/planning/assignment-summary',
    parent: 'Planning',
    priority: 4
  },
  {
    name: 'Tasks by Wave',
    title: 'Tareas generadas por ola',
    routerLink: '/planning/tasks-by-wave',
    parent: 'Planning',
    priority: 5
  },
  {
    name: 'Short Allocation',
    title: 'Cortos',
    routerLink: '/planning/short-allocation',
    parent: 'Planning',
    priority: 6
  },
  {
    name: 'Picking List',
    title: 'Surtido de lista',
    routerLink: '/picking-list',
    parent: 'Planning',
    priority: 7
  },
  {
    name: 'Picking Pallet',
    title: 'Surtido de Pallet',
    routerLink: '/picking-pallet',
    parent: 'Planning',
    priority: 8
  },
  {
    name: 'Replenishment Configuration',
    title: 'Configuración de reabastos',
    routerLink: '/replenishment-configuration',
    parent: 'Replenishment',
    priority: 1
  },
  {
    name: 'Replenishment Top off',
    title: 'Reabasto Top off',
    routerLink: '/replenishment',
    parent: 'Replenishment',
    priority: 2
  },
  {
    name: 'Shipment',
    title: 'Embarque',
    routerLink: '/shipment',
    parent: 'Shipment',
    priority: 1
  },
  {
    name: 'Load Vehicle',
    title: 'Cargar Vehículo',
    routerLink: '/shipment/process',
    parent: 'Shipment',
    priority: 2,
    mobile: true
  },
  {
    name: 'Receipt',
    title: 'Recibo',
    routerLink: '/receipt',
    parent: 'Receipt',
    priority: 1,
    mobile: true
  },
  {
    name: 'Receipt Order',
    title: 'Carga orden de entrada',
    routerLink: '/receipt/receipt-order',
    parent: 'Receipt',
    priority: 2
  },
  {
    name: 'Progress Order Receipt',
    title: 'Avance de orden',
    routerLink: '/progress-receipt-order',
    parent: 'Receipt',
    priority: 3
  },
  {
    name: 'Receipt Appointment',
    title: 'Citas',
    routerLink: '/receipt/receipt-appointment-calendar',
    parent: 'Receipt',
    priority: 4
  },
  {
    name: 'Truck Arrival',
    title: 'Entrada de camión',
    routerLink: '/truck-arrival',
    parent: 'Receipt',
    priority: 5
  },
  {
    name: 'Receipt Per Piece',
    title: 'Recibo por Pieza',
    routerLink: '/receipt/receipt-pieces',
    parent: 'Receipt',
    priority: 6
  },
  {
    name: 'Picking',
    title: 'Surtido',
    routerLink: '/picking/select',
    parent: 'Picking',
    priority: 1,
    mobile: true
  },
  {
    name: 'Progress Shipment Order',
    title: 'Avance de surtido',
    routerLink: '/progress-shipment-order',
    parent: 'Picking',
    priority: 2
  },
  {
    name: 'Material List',
    title: 'Lista de materiales',
    routerLink: '/kitting/material-list',
    parent: 'Kitting',
    priority: 1
  },
  {
    name: 'Kitting Order',
    title: 'Orden de Trabajo',
    routerLink: '/kitting/kitting-order',
    parent: 'Kitting',
    priority: 2
  },
  {
    name: 'Kit Reception',
    title: 'Recepción de kits',
    routerLink: '/kitting/kit-reception',
    parent: 'Kitting',
    priority: 3
  },
  {
    name: 'Take Apart kit',
    title: 'Desarmar kits',
    routerLink: '/kitting/take-apart',
    parent: 'Kitting',
    priority: 4,
    mobile: true
  },
  {
    name: 'Packstation',
    title: 'Packstation',
    routerLink: '/pack-station',
    parent: 'Packstation',
    priority: 1
  },
  {
    name: 'Package Shipment',
    title: 'Embarque Paqueteras',
    routerLink: '/package-shipment',
    parent: 'Packstation',
    priority: 2
  },
  {
    name: 'Packstation Report',
    title: 'Reporte Packstation',
    routerLink: '/pack-station-report',
    parent: 'Packstation',
    priority: 3
  },
  {
    name: 'Interfaces Logs',
    title: 'Registro de interfaces',
    routerLink: '/interfaces-logs',
    parent: 'Interfaces Logs',
    priority: 1
  }
];

export const PARENTS: IMenuInterface[] = [
  {
    name: 'Home',
    title: 'Inicio',
    icon: 'home',
    routerLink: '/home',
    priority: 1
  },
  {
    name: 'Configuration',
    title: 'Configuración',
    icon: 'configuration',
    items: [],
    priority: 2
  },
  {
    name: 'Catalogs',
    title: 'Catálogos',
    icon: 'catalogs',
    items: [],
    priority: 3
  },
  {
    name: 'Receipt',
    title: 'Recibo',
    icon: 'receipt',
    items: [],
    priority: 4,
    mobile: true,
    mobilePriority: 1
  },
  {
    name: 'Picking',
    title: 'Surtido',
    icon: 'picking',
    items: [],
    priority: 5,
    mobile: true,
    mobilePriority: 2
  },
  {
    name: 'Reallocate',
    title: 'Reubicar',
    icon: 'reallocate',
    items: [],
    priority: 6,
    mobile: true,
    mobilePriority: 3
  },
  {
    name: 'Reports',
    title: 'Reportes',
    icon: 'report',
    items: [],
    priority: 7,
    mobile: true,
    mobilePriority: 9
  },
  {
    name: 'Tasks',
    title: 'Tareas',
    icon: 'task',
    routerLink: '/task/select',
    priority: 8,
    mobile: true,
    mobilePriority: 4
  },
  {
    name: 'Inventory',
    title: 'Inventario',
    icon: 'inventory',
    items: [],
    priority: 9,
    mobile: true,
    mobilePriority: 6
  },
  {
    name: 'Planning',
    title: 'Planeación',
    icon: 'planning',
    items: [],
    priority: 10
  },
  {
    name: 'Replenishment',
    title: 'Reabasto',
    icon: 'replenishment',
    items: [],
    priority: 11
  },
  {
    name: 'Shipment',
    title: 'Embarque',
    icon: 'shipment',
    items: [],
    priority: 12,
    mobile: true,
    mobilePriority: 7
  },
  {
    name: 'Dashboards',
    title: 'Dashboards',
    icon: 'dashboard',
    routerLink: '/dashboard',
    priority: 13
  },
  {
    name: 'Kitting',
    title: 'Kitting',
    icon: 'kitting',
    items: [],
    priority: 14,
    mobile: true,
    mobilePriority: 8
  },
  {
    name: 'Packstation',
    title: 'Packstation',
    icon: 'packstation',
    items: [],
    priority: 15
  },
  {
    name: 'Assigned Task',
    title: 'Trabajo Dirigido',
    icon: 'kitting',
    routerLink: '/assigned-task',
    priority: 16,
    mobile: true,
    mobilePriority: 5
  },
  {
    name: 'Interfaces Logs',
    title: 'Registro de interfaces',
    icon: 'interfaces',
    priority: 17,
    items: []
  },
];
