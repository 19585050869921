export class PickListOptions {
  static readonly DRAGDROP: boolean = true;
  static readonly NAME: boolean = false;
  static readonly NICKNAME: boolean = false;
  static readonly ORDER_LIST: boolean = true;
  static readonly RESPONSIVE: boolean = true;
  static readonly SOURCE_CONTROLS: boolean = false;
  static readonly TARGET_CONTROLS: boolean = false;
  static readonly FIELD: string = 'orderNumber';
}
