import * as moment from 'moment';
import * as momentTimeZone from 'moment-timezone';

import * as _ from 'lodash';
import { CONSTANTS } from './constants';

const DATE_FORMAT = 'YYYY-MM-DD';
const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const VALID_DATE = -1;

export class FormatDate {

  /**
  * @description Formats param's date to string.
  * @param {Date} date Date to format into string.
  * @return {string} Formatted date.
  */
  public static getStringDate(date: Date): string {
    let stringDate: string;
    stringDate = date.getFullYear() + '-'
      + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
    return stringDate;
  }

  /**
   * @description Validates if the date given matches the format needed
   * @param {date} string - Contains the date to validate
   * @return {boolean} - Return true if it matches otherwise, return false
   */
  public static validateDateFormat(date: string): boolean {
    let regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (date.match(regEx) !== null) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * @description Validates if the date given is valid
   * @param {date} string - Contains the date to validate
   * @return {boolean} - Return true if it's valid and if it doesn't return false
   */
  public static validateDateRange(date: string): boolean {
    let isOverflowDate: number = moment(date, DATE_FORMAT).invalidAt();
    return _.isEqual(isOverflowDate, VALID_DATE);
  }

  /**
   * @description Returns the given time in an MSSQL timestamp
   * @return {string} Timestamp in the database format
   */
  public static getCurrentDateString(): string {
    let now: string = moment().format(DATE_TIME_FORMAT);
    return now;
  }

  /**
   * @description Returns the given time in an MSSQL timestamp
   * @return {string} Timestamp in the database format
   */
  public static parseDateSessionTimezone(date: Date): any {
    let timeZone: string = localStorage.getItem(CONSTANTS.TIME_ZONE);
    return momentTimeZone.tz(date, timeZone).format(DATE_TIME_FORMAT);
  }


  /**
   * @description Returns date in the selected format
   * @param {Date} date Selected date to format
   * @return {Date} Formatted Date
   */
   public static getFormattedDate(date: Date): Date {
     let formattedDate: any = moment(date, DATE_FORMAT).format(DATE_FORMAT);
     return formattedDate;
   }

  /**
   * @description Returns the current Date in an MSSQL timestamp
   * @return {Date} - Current date in the database format
   */
  public static getThisMoment(timeZone: string): Date {
    let now: string =  momentTimeZone().tz(timeZone).format(DATE_TIME_FORMAT);
    let date: Date = new Date(now);
    return date;
  }

  /**
   * @description adds quantity of units (e.g 7 days) to a given date
   * @return {Date} - added date
   */
  public static addTimeToDate(date: Date, quantity: number, units: moment.unitOfTime.DurationConstructor): Date {
    let newDate: Date = moment(date).add(quantity, units).toDate();
    return newDate;
  }

  /**
   * @description Returns the given dateTime in a date
   * @param {Date} date DateTime to format
   * @param {string} date DateTime to format
   * @return {string} Date in string
   */
  public static dateToString(date: Date | string): string {
    let now: string = moment(date, DATE_FORMAT).format(DATE_FORMAT);
    return now;
  }

  /**
   * @description Returns Date validity to check if it's actually a Date or Date string
   * @param {Date|string} date Date/String to validate wether it is a valid Date
   * @return {boolean} Is Valid or Not
   */
  public static validateDate(date: Date | string): boolean {
    return moment(date, DATE_FORMAT, true).isValid();
  }

  /**
   * @description Returns the actual date in utc format
   * @return {string}
   */
  public static dateNowUTCFormat(): string {
    let dateUTCFormat = moment.utc().format();
    return dateUTCFormat;
  }

}
