import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CarouselModule } from 'primeng/carousel';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { CommonModule } from '@angular/common';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { NgModule } from '@angular/core';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from 'primeng/password';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SharedModule } from 'primeng/api';
import { SpinnerModule } from 'primeng/spinner';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';

import { AutocompleteWrapperModule } from '../shared/templates/html/autocomplete-wrapper/autocomplete-wrapper.module';
import { AutoFocusModule } from '../shared/directives/autofocus/autofocus.module';
import { BaseFormWrapperModule } from '../shared/templates/html/form/base-form-wrapper/base-form-wrapper.module';
import { ConfirmWrapperModule } from '../shared/templates/html/confirm-wrapper/confirm-wrapper.module';
import { FieldWrapperModule } from '../shared/templates/html/form/field-wrapper/field-wrapper.module';
import { FootprintWrapperModule } from '../shared/footprint-wrapper/footprint-wrapper.module';
import { GridWrapperModule } from '../shared/templates/html/grid/grid-wrapper.module';
import { NumberOnlyModule } from '../shared/directives/number-only/number-only.module';
import { PickListWrapperModule } from '../shared/templates/html/pick-list-wrapper/pick-list-wrapper.module';
import { SnapshotModule } from '../shared/directives/snapshot/snapshot.module';

@NgModule({
  imports: [
    AccordionModule,
    AutoCompleteModule,
    AutocompleteWrapperModule,
    AutoFocusModule,
    BaseFormWrapperModule,
    ButtonModule,
    CalendarModule,
    CarouselModule,
    CheckboxModule,
    ChipsModule,
    CommonModule,
    ConfirmWrapperModule,
    ContextMenuModule,
    TableModule,
    DialogModule,
    DropdownModule,
    FieldsetModule,
    FieldWrapperModule,
    FileUploadModule,
    FootprintWrapperModule,
    GridWrapperModule,
    InputSwitchModule,
    InputTextareaModule,
    InputTextModule,
    KeyFilterModule,
    ListboxModule,
    MenuModule,
    MessagesModule,
    MultiSelectModule,
    NumberOnlyModule,
    OverlayPanelModule,
    PanelMenuModule,
    PasswordModule,
    PickListWrapperModule,
    ProgressBarModule,
    RadioButtonModule,
    SelectButtonModule,
    SharedModule,
    SnapshotModule,
    SpinnerModule,
    TabViewModule,
    ToolbarModule,
    TooltipModule
  ],
  exports: [
    AccordionModule,
    AutoCompleteModule,
    AutocompleteWrapperModule,
    AutoFocusModule,
    BaseFormWrapperModule,
    ButtonModule,
    CalendarModule,
    CarouselModule,
    CheckboxModule,
    ChipsModule,
    CommonModule,
    ConfirmWrapperModule,
    ContextMenuModule,
    TableModule,
    DialogModule,
    DropdownModule,
    FieldsetModule,
    FieldWrapperModule,
    FileUploadModule,
    FootprintWrapperModule,
    GridWrapperModule,
    InputSwitchModule,
    InputTextareaModule,
    InputTextModule,
    KeyFilterModule,
    ListboxModule,
    MenuModule,
    MessagesModule,
    MultiSelectModule,
    NumberOnlyModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    PasswordModule,
    PickListWrapperModule,
    ProgressBarModule,
    RadioButtonModule,
    SelectButtonModule,
    SharedModule,
    SnapshotModule,
    SpinnerModule,
    TabViewModule,
    ToolbarModule,
    TooltipModule
  ]
})
export class UtilsModule { }
