import { EquipmentType } from '../equipment-type/equipment-type';
import { Location } from '../location/location';
import { User } from '../../administration/user/user';

export class Equipment {
  id: number;
  code: string;
  description: string;
  equipmentType: EquipmentType;
  isAvailable: boolean;
  checkNumber: string;
  isActive: boolean;
  lastChange: Date;
  location: Location;
  usedBy: number;
  userId: number;
  user: User;
  warehouseId: number;

  constructor() {
    this.id = undefined;
    this.code = '';
    this.description = '';
    this.equipmentType = new EquipmentType();
    this.isAvailable = false;
    this.checkNumber = '';
    this.isAvailable = false;
    this.lastChange = new Date();
    this.location = new Location();
    this.usedBy = undefined;
    this.userId = undefined;
    this.user = new User();
    this.warehouseId = undefined;
  }
}
