import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { NgModule } from '@angular/core';
import { PanelMenuModule } from 'primeng/panelmenu';
import { SlideMenuModule } from 'primeng/slidemenu';
import { TabMenuModule } from 'primeng/tabmenu';
import { TooltipModule } from 'primeng/tooltip';

import { ReleaseTaskService } from '../services/release-task.service';
import { SidebarWrapperComponent } from './sidebar-wrapper.component';
import { TaskGenericWrapperService } from '../templates/html/task-wrapper/task-wrapper.service';

@NgModule({
 imports: [ButtonModule, CommonModule, TabMenuModule, PanelMenuModule, DialogModule, SlideMenuModule,
   TooltipModule],
 declarations: [SidebarWrapperComponent],
 exports: [SidebarWrapperComponent],
 providers: [TaskGenericWrapperService, ReleaseTaskService]
})
export class SidebarWrapperModule { }
