import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ENVIRONMENT } from '../../../../environments/environment';

const INSIGHTS_PAGEVIEW_ERROR = 'Application Insights page view cannot be tracked: ';
const INSIGHTS_EVENT_ERROR = 'Application Insights Event cannot be tracked: ';
const INSIGHTS_ERROR_ERROR = 'Application Insights Error cannot be tracked: ';
const INSIGHTS_USERAUTH_ERROR = 'Application Insights user authentication cannot be set: ';
const INSIGHTS_CLEARAUTH_ERROR = 'Application Insights user authentication cannot be cleared: ';
const INSIGHTS_METRIC_ERROR = 'Application Insights metric cannot be tracked: ';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsMonitoringService {

  private routerSubscription: Subscription;
  private appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: ENVIRONMENT.APPINSIGHTS_INSTRUMENTATIONKEY
    }
  });

  constructor(private router: Router) {
    if (ENVIRONMENT.PRODUCTION === true && this.appInsights.config.instrumentationKey !== undefined) {
      this.appInsights.loadAppInsights();
      this.routerSubscription = this.router.events.pipe(
        filter(event => event instanceof ResolveEnd))
        .subscribe((event: ResolveEnd) => {
          const activatedComponent = this.getActivatedComponent(event.state.root);
          if (activatedComponent) {
            this.appInsights.addTelemetryInitializer((envelope) => {
                envelope.tags['ai.operation.name'] = event.urlAfterRedirects;
            });
            this.logPageView(`${activatedComponent.name}`, event.urlAfterRedirects);
          }
        }
        );
    }
  }

  /**
   * @description Log pages views caught.
   * @param name<string> - Name of the page view.
   * @param uri - Relative or absolute URL that identifies the page.
   * @param properties - Additional data used to filter pages.
   * @param refUri - URL of the previous page that sent the user to the current page.
   */
  public logPageView(name?: string, uri?: string, properties?: { [key: string]: any }, refUri?: string): void {
    try {
      if (ENVIRONMENT.PRODUCTION === true && this.appInsights.config.instrumentationKey !== undefined) {
        this.appInsights.trackPageView({ name, uri, properties, refUri });
      }
    } catch (error) {
      throw new Error(`${INSIGHTS_PAGEVIEW_ERROR} ${error}`);
    }
  }

  /**
   * @description Log events caught.
   * @param name - Name of the event.
   * @param properties - Additional data used to filter events.
   */
  public logEvent(name: string, properties?: { [key: string]: any }): void {
    try {
      if (ENVIRONMENT.PRODUCTION === true && this.appInsights.config.instrumentationKey !== undefined) {
        this.appInsights.trackEvent({ name, properties });
      }
    } catch (error) {
      throw new Error(`${INSIGHTS_EVENT_ERROR} ${error}`);
    }
  }

  /**
   * @description Log exceptions caught.
   * @param error - Error object.
   * @param properties - Additional data used to filter exceptions.
   * @param severityLevel - Severity of the exception.
   */
  public logError(error: any, properties?: { [key: string]: any }, severityLevel?: number): void {
    try {
      if (ENVIRONMENT.PRODUCTION === true && this.appInsights.config.instrumentationKey !== undefined) {
        this.appInsights.trackException({ error, properties, severityLevel });
      }
    } catch (err) {
      throw new Error(`${INSIGHTS_ERROR_ERROR} ${err}`);
    }
  }

  /**
   * @description Log custom metrics caught.
   * @param name - Custom metric name.
   * @param value - Custom metric value.
   * @param properties - Additional data used to filter custom metrics.
   */
  public logMetric(metricName: string, value?: number, properties?: { [key: string]: string }): void {
    try {
      if (ENVIRONMENT.PRODUCTION === true && this.appInsights.config.instrumentationKey !== undefined) {
        this.appInsights.trackMetric({ name: metricName, average: value }, { customProperties: properties });
      }
    } catch (error) {
      throw new Error(`${INSIGHTS_METRIC_ERROR} ${error}`);
    }
  }

  /**
   * @description Set the authenticated user id and the account id.
   * Use this when you have identified a specific signed-in user.
   * @param userId - User id identifier.
   * @param accountId - User account identifier.
   * @param storeInCookie - Cookie to set them for all events within the whole session.
   */
  public setAuthenticatedUserId(userId: string, accountId?: string, storeInCookie?: boolean): void {
    try {
      if (ENVIRONMENT.PRODUCTION === true && this.appInsights.config.instrumentationKey !== undefined) {
        this.appInsights.setAuthenticatedUserContext(userId, accountId, storeInCookie);
      }
    } catch (error) {
      throw new Error(`${INSIGHTS_USERAUTH_ERROR} ${error}`);
    }
  }

  /**
   * @description Clears the authenticated user id and the account id from the user context,
   * and clears the associated cookie.
   */
  public clearUserId(): void {
    try {
      if (ENVIRONMENT.PRODUCTION  === true && this.appInsights.config.instrumentationKey !== undefined) {
        this.appInsights.clearAuthenticatedUserContext();
      }
    } catch (error) {
      throw new Error(`${INSIGHTS_CLEARAUTH_ERROR} ${error}`);
    }
  }

  /**
   * @description Trace the component of the innermost route.
   * @param snapshot - Route associated with a component loaded.
   * @returns - Component traced
   */
  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): string | any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }
    return snapshot.component;
  }
}
