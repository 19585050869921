export class UOM {
  id: number;
  code: string;
  description: string;
  isActive: boolean;
  lastChange: Date;
  userId: number;

  constructor() {
    this.id = undefined;
    this.code = '';
    this.description = '';
    this.isActive = false;
    this.lastChange = new Date();
    this.userId = undefined;
  }
}
