import { Component, OnInit, AfterViewInit } from '@angular/core';
import { filter, pairwise } from '../../node_modules/rxjs/operators';
import { HttpErrorResponse } from '../../node_modules/@angular/common/http';
import { MenuItem } from 'primeng/api/menuitem';
import { Router, RoutesRecognized } from '@angular/router';
import { ScfCommunicationService, ScfNotification, ScfToastrService } from 'scf-library';
import * as _ from 'lodash';

import { AuthService } from './shared/authentication/services/auth.service';
import { CONSTANTS } from './shared/constants';
import { IMenuInterface } from './models/menu-interface';
import { LabelService } from './administration/label-management/label.service';
import { MobileConfig } from './shared/constants/mobile-config';
import { ReleaseTask } from './shared/interfaces/release-task';
import { ReleaseTaskService } from './shared/services/release-task.service';
import { SidebarWrapperService } from './shared/mobile-sidebar/sidebar-wrapper.service';
import { TaskGenericWrapperService } from './shared/templates/html/task-wrapper/task-wrapper.service';
import { TaskStatusEnum } from './process/task/task-enums';
import { User } from './administration/user/user';
import { WepError } from './shared/wep-error';

@Component({
  selector: 'app-pages-component',
  templateUrl: './pages.component.html',
  providers: [AuthService]
})
export class PagesComponent implements OnInit, AfterViewInit {
  private previousRoute: string;
  public currentRoute: string;
  public dockedSize: string;
  public isLoggedIn: boolean;
  public lbl: any;
  public menu: IMenuInterface[];
  public mobile: boolean;
  public mode: string;
  public msgs: any;
  public opened: boolean;
  public position: string;
  public showBackDrop: boolean;
  public trapFocus: boolean;
  public userInfo: any;
  public userSettingsBackEl: any;
  public userSettingsItems: MenuItem[];
  public solution: string;
  public owner: string;
  public display: boolean;

  constructor(private authService: AuthService,
    private labelService: LabelService,
    private notifier: ScfToastrService,
    private releaseTaskService: ReleaseTaskService,
    private router: Router,
    private scfComService: ScfCommunicationService,
    private sidebarWrapperService: SidebarWrapperService,
    private taskWrapperService: TaskGenericWrapperService) {
    this.currentRoute = null;
    this.isLoggedIn = this.isAuthenticated();
    this.lbl = {
      info: this.labelService.labelText('infoPages'),
      sessionExpired: this.labelService.labelText('sessionExpired'),
      successReleaseTask: this.labelService.labelText('taskWrapperUnSelectTask'),
      successTitle: this.labelService.labelText('infoTitle')
    };
    this.mobile = window.screen.width >= MobileConfig.WINDOW_WIDTH ? false : true;
    this.msgs = {
      userInfoError: {
        tlt: this.labelService.labelText('errorTitle'),
        msg: this.labelService.labelText('sidebarUserInfoError')
      },
      releaseTaskError: {
        tlt: this.labelService.labelText('errorTitle'),
        msg: this.labelService.labelText('releaseTaskError')
      }
    };
    this.buildMenu();
    this.display = false;
    this.opened = false;
    this.owner = '';
    this.previousRoute = null;
    this.showBackDrop = true;
    this.solution = CONSTANTS.WEP;
    this.trapFocus = true;
    this.userInfo = { name: '' };
  }

  /**
   * @description App init Component.
   * @return {void}
   */
  public ngOnInit(): void {
    this.subscribeLoggedStatus();
    this.subscribeRoutes();
    this.getLoggedUser();
    this.subscribeToScfEvents();
  }

  public ngAfterViewInit(): void {
    // this.verifyWrapperStatusSidebar();
  }

  /**
   * @description Subscribes to the observable that indicated if the user is logged-in or not
   * @return {void}
   */
  public subscribeLoggedStatus(): void {
    this.authService.getLoggedInObservable().subscribe(
      (isLoggedIn: boolean) => {
        if (isLoggedIn !== undefined) {
          this.isLoggedIn = isLoggedIn;
          if (isLoggedIn === false) {
            this.authService.logout(true);
          }
          this.setWrapperClass();
        }
      });
  }

  /**
   * @description Returns true if the token stored in the localstorage key 'id_token' is not expired and false
   * if it has expired or it doesn't exist.
   * @return {boolean}
   */
  public isAuthenticated(): boolean {
    if (this.authService.isAuthenticated()) {
      return true;
    }
    if (this.previousRoute !== null && _.isEqual(this.router.url, CONSTANTS.LOGIN_URL)) {
      this.previousRoute = null;
      this.notifier.infoAlert(this.lbl.sessionExpired);
    }
    if (_.isEqual(this.router.url, CONSTANTS.LOGIN_URL)) {
      return false;
    } else {
      this.authService.logout();
    }
    return false;
  }

  /**
   * @description Set class for page-content-wrapper
   * @return {string} Wrapper class
   */
  public setWrapperClass(): string {
    let wClass = '';
    return wClass;
  }

  /**
   * @description Subscribes to the Router Service and switches current and previous route accordingly
   * @return {void}
   */
  private subscribeRoutes(): void {
    this.router.events
      .pipe(filter(event => event instanceof RoutesRecognized))
      .pipe(pairwise())
      .subscribe((events: any[]) => {
        this.currentRoute = events.pop().url;
        this.previousRoute = events.pop().url;
      });
  }

  /**
   * @description Navigate to a previously route selected.
   * @param {Array<String>} routerLink routerLink property to navigate.
   * @return {void}
   */
  public navigateTo(routerLink: Array<string>): void {
    this.router.navigate(routerLink);
  }

  /**
   * @description Build the sidebar Menu
   * @return {void}
   */
   public buildMenu(): void {
    this.menu = this.authService.getAllowedRoutes();
    if (this.mobile) {
      _.remove(this.menu, (mobileRoutes) => _.isUndefined(mobileRoutes.mobile));
    }
  }

  /**
   * @description Gets user logged information.
   * @return {void}
   */
  public getLoggedUser(): void {
    this.authService.getUserInfo().subscribe(
      (user: User) => {
        this.userInfo.name = user.name + ' ' + user.surname;
        this.owner = this.userInfo.name;
        this.authService.setTimeZone(user.profile.timeZone);
      }, (error: HttpErrorResponse) => {
        if (_.isEqual(error.status, CONSTANTS.AUTHORIZATION)) {
          this.authService.logout();
        }
      });
  }

  /**
   * @description This method redirect to login screen
   * @return {void}
   */
  public logOutUser(): void {
    this.validateReleaseTask();
  }

  /**
   * @description validate release task.
   * @return {void}
   */
  private validateReleaseTask(): void {
    this.releaseTaskService.getReleaseTaskAsObservable().subscribe((taskToRelease: ReleaseTask) => {
      if (!_.isUndefined(taskToRelease)) {
        if (_.isEqual(taskToRelease.component, CONSTANTS.PICKING_LIST_COMPONENT) &&
          _.isEqual(taskToRelease.status, TaskStatusEnum.Assigned)) {
          this.taskWrapperService.releasePickingList(taskToRelease).subscribe(() => {
            this.notifier.infoAlert(this.lbl.successReleaseTask);
            this.authService.logout();
            this.releaseTaskService.releaseObservable();
          },
            (error: WepError) => {
              this.notifier.errorAlert(this.msgs.releaseTaskError.msg);
            });
        } else {
          this.authService.logout();
        }
      } else {
        this.authService.logout();
      }
    });
  }

  /**
   * @description Subscribes to SCF Events
   * @return {void}
   */
  private subscribeToScfEvents(): void {
    // Notification Ceter
    this.scfComService.notificationReadSubscribe()
      .subscribe((notification: ScfNotification) => {
        // TODO: @rperez 2021/01/01 Put logic about notification center here.
      });

    // Sidebar & Search Box navigate to
    this.scfComService.navigateToSubscribe()
      .subscribe((routerLink: string) => {
        if (routerLink) {
          this.router.navigate([routerLink]);
        }
      });

    // User's panel solution selected
    this.scfComService.solutionChangedSubscribe()
      .subscribe((solution: string) => {
        if (solution) {
          this.solution = solution;
        }
      });

    // Logout Service
    this.scfComService.logoutSubscribe()
      .subscribe((navRequest: any) => {
        if (!navRequest) {
          this.logOutUser();
        }
      });

    // Sidebar Service
    this.scfComService.sidebarSubscribe()
      .subscribe(() => {
        if (this.mobile) {
          this.display = !this.display;
          this.sidebarWrapperService.sidebarWrapperToggle(this.display);
        }
      });
  }

  /**
   * @description Breakpoint to validate if menu closes
   * @return {void}
   */
  public closePanelNotify(): void {
    this.display = false;
  }
}
