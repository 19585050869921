import { CommonModule } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { ConfirmWrapperComponent } from './confirm-wrapper.component';
import { NgModule } from '@angular/core';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@NgModule({
  imports: [CommonModule, ConfirmDialogModule],
  declarations: [ConfirmWrapperComponent],
  exports: [ConfirmWrapperComponent],
  providers: [ConfirmationService]
})
export class ConfirmWrapperModule { }
