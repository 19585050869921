import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-field-wrapper',
  templateUrl: 'field-wrapper.component.html',
  styleUrls: ['field-wrapper.component.css']
})
export class FieldWrapperComponent {

  @Input() fieldLabel: string;
  @Input() fieldName: string;
  @Input() errors?: string | any;
  @Input() column?: boolean;
}
