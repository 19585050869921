import { Component, HostListener, Input } from '@angular/core';
import { KeyCode } from '../../../../constants/keycode';
import * as _ from 'lodash';

const INPUT = 'INPUT';

@Component({
  selector: 'app-base-form-wrapper',
  templateUrl: 'base-form-wrapper.component.html'
})

export class BaseFormWrapperComponent {
  @Input() formTitle: string;
  @Input() formClass?: string;

  /**
   * @description Listens for user's keys for custom functions
   */
  @HostListener('document:keydown', ['$event'])
  onKeyDown(ev: KeyboardEvent) {
    // [Enter] key to prevent submitting form
    let tagName = (ev.target as HTMLInputElement).tagName;
    if (_.isEqual(tagName, INPUT) && _.isEqual(ev.key, KeyCode.Enter)) {
      ev.preventDefault();
    }
  }
}
