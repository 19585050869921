import { Component } from '@angular/core';

import { LabelService } from '../../administration/label-management/label.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html'
})
export class PageNotFoundComponent {

  public lbl = {
    pageNotFound: this.labelService.labelText('pageNotFound'),
    errorRoute: this.labelService.labelText('errorRoute'),
    back : this.labelService.labelText('backButton')
  };

  constructor(private labelService: LabelService) { }
}
